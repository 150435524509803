import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import ThemeContext from "../context/ThemeContext"
import NavContext from "../context/NavContext"

const StyledNavigation = styled.nav`
  --accent: ${props => (props.accent ? props.accent : "#FFE747")};
  --foreground: ${props => (props.foreground ? props.foreground : "black")};
  --background: ${props => (props.background ? props.background : "white")};
  color: ${props => (props.foreground ? props.foreground : "black")};
  position: fixed;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  display: none;

  @media (min-width: 1350px) {
    display: block;
  }

  ul {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }

  &:hover {
    .navItem-Text {
      opacity: 1;
      transform: translateX(0);
    }
  }
`

const NavItem = styled.li`
  display: block;
  position: relative;
  margin-bottom: 40px;
  font-family: "Saira";
  font-size: 18px;
  color: inherit;
  cursor: pointer;

  .navItem-Link {
    display: block;
    position: relative;
    text-decoration: none;
    padding-right: 32px;

    &:after {
      display: block;
      position: absolute;
      right: 0;
      top: 6px;
      content: "";
      height: 16px;
      width: 16px;
      background-color: white;
      background-color: var(--foreground);
      transition: background-color 0.5s, transform 0.5s;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  .navItem-Text {
    display: block;
    opacity: 0;
    color: white;
    color: var(--foreground);
    transition: opacity 0.3s, transform 0.3s, color 0.5s;
    transform: translateX(24px);
  }

  &.active {
    .navItem-Link:after {
      background-color: #ffe747;
      background-color: var(--accent);
      transform: rotate(45deg);
    }

    .navItem-Text {
      color: #ffe747;
      color: var(--accent);
    }
  }

  &:hover {
    .navItem-Text {
      opacity: 0.7;
    }
  }
`

const Navigation = props => {
  const navPoints = useStaticQuery(graphql`
    query {
      allNavYaml {
        edges {
          node {
            name
            slug
            id
          }
        }
      }
    }
  `)

  return (
    <ThemeContext.Consumer>
      {theme => (
        <NavContext.Consumer>
          {nav => {
            return (
              <StyledNavigation
                foreground={theme.foreground}
                accent={theme.accent}
                background={theme.background}
              >
                <ul>
                  {navPoints.allNavYaml.edges.map(item => (
                    <NavItem
                      className={
                        nav.activeNav === item.node.slug
                          ? "navItem active"
                          : "navItem"
                      }
                      key={item.node.slug}
                    >
                      <a href={`#${item.node.slug}`} className="navItem-Link">
                        <span className="navItem-Text">{item.node.name}</span>
                      </a>
                    </NavItem>
                  ))}
                </ul>
              </StyledNavigation>
            )
          }}
        </NavContext.Consumer>
      )}
    </ThemeContext.Consumer>
  )
}

export default Navigation
