import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import ThemeContext from "../context/ThemeContext"
import ImageContext from "../context/ImageContext"

import Close from "@assets/icons/close.svg"

const ZoomedImageContainer = styled.div`
  --accent: ${props => (props.accent ? props.accent : "#FFE747")};
  --foreground: ${props => (props.foreground ? props.foreground : "black")};
  --background: ${props => (props.background ? props.background : "white")};

  color: ${props => (props.foreground ? props.foreground : "black")};
  background-color: ${props => (props.background ? props.background : "white")};

  display: block;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;

  .zoomedImage-inner {
    margin-top: 80px;
  }

  .closeButton {
    background: none;
    position: absolute;
    top: 24px;
    right: 24px;
    border: none;
    outline: none;
    height: 40px;
    width: 40px;
    padding: 0;
    color: black;
    color: var(--foreground);

    .icon {
      display: block;
      width: 40px;
      height: 40px;
    }

    @media (min-width: 1000px) {
      right: calc((100vw - 1000px) / 2 + 24px);
    }
  }
`

const ZoomedImage = () => (
  <ThemeContext.Consumer>
    {theme => (
      <ImageContext.Consumer>
        {image => {
          if (image.activeImageName !== "") {
            return (
              <ZoomedImageContainer
                foreground={theme.foreground}
                background={theme.background}
                accent={theme.accent}
              >
                <button
                  onClick={image.clearImage}
                  aria-label="close"
                  className="closeButton"
                >
                  <Close className="icon" />
                </button>
                <div className="layout-container zoomedImage-inner">
                  <Img fluid={image.activeImage.fluid} className="image" />
                  <p className="paragraph paragraph--big margin-top-l">
                    {image.activeImageName}
                  </p>
                </div>
                {/* {JSON.stringify(image */}
              </ZoomedImageContainer>
            )
          }
        }}
      </ImageContext.Consumer>
    )}
  </ThemeContext.Consumer>
)

export default ZoomedImage
