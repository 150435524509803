import React, { useContext, useState } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { InView } from "react-intersection-observer"

import Layout from "@components/layout"
import SEO from "@components/seo"
import ThemeContext from "../context/ThemeContext"
import NavContext from "../context/NavContext"
import ImageContext from "../context/ImageContext"

import Section from "@components/section"
import Project from "@components/project"
import Gitlab from "@assets/icons/gitlab.svg"
import Github from "@assets/icons/github.svg"
import Sport from "@assets/icons/sport.svg"
import Photography from "@assets/icons/photography.svg"
import Cats from "@assets/icons/cats.svg"
import Cooking from "@assets/icons/cooking.svg"
import LinkedIn from "@assets/icons/linked-in.svg"
import Instagram from "@assets/icons/instagram.svg"
import Twitter from "@assets/icons/twitter.svg"

import "@assets/styles/styles.css"

const SectionHeadline = styled.h2`
  font-family: "Rubik Mono One", sans-serif;
  color: inherit;
  font-size: 24px;
  text-align: center;
  width: 100%;
  margin-bottom: 64px;

  @media (min-width: 480px) {
    font-size: 32px;
  }

  @media (min-width: 680px) {
    font-size: 48px;
  }
`

const ProjectsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-row-gap: 80px;
  width: 100%;

  @media (min-width: 680px) {
    grid-column-gap: 40px;
  }

  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 80px;
  }
`

const FurtherLinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 48px;

  .icon {
    margin-right: 16px;
  }
`

const TechContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-bottom: 64px;
`

const InterestContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Interest = styled.div`
  display: flex;
  flex-direction: row;

  & .icon {
    width: 32px;
    height: 32px;
    margin-right: 24px;
  }
`

const TRESHOLD = [0.01, 0.02]

const IndexPage = () => {
  const dataQuery = useStaticQuery(graphql`
    query {
      allProjectsYaml {
        edges {
          node {
            image {
              childImageSharp {
                fluid(
                  maxWidth: 1000
                  srcSetBreakpoints: [380, 480, 680, 1000]
                  quality: 85
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            description
            link
            name
            subheadline
          }
        }
      }
      allInterestYaml {
        edges {
          node {
            name
            icon
          }
        }
      }
      allImageSharp(filter: { fluid: { originalName: { eq: "katzen.jpg" } } }) {
        edges {
          node {
            fluid(
              maxWidth: 1000
              srcSetBreakpoints: [380, 480, 680, 1000]
              quality: 85
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      allNavYaml {
        edges {
          node {
            name
            slug
          }
        }
      }
    }
  `)

  const projects = dataQuery.allProjectsYaml.edges
  const imageContext = useContext(ImageContext)
  const image = dataQuery.allImageSharp.edges[0].node
  const navPoints = dataQuery.allNavYaml.edges

  const openCatImage = () => {
    imageContext.setImage({ image, imageName: "KATZEN" })
  }

  const interests = dataQuery.allInterestYaml.edges
  const interestIcons = {
    sport: <Sport className="icon" />,
    cooking: <Cooking className="icon" />,
    cats: (
      <Cats
        className="icon"
        onClick={openCatImage}
        onKeyDown={openCatImage}
        role="button"
        tabIndex={0}
      />
    ),
    photography: <Photography className="icon" />,
  }

  const [rootMargin] = useState(`-49.5% 0px`)
  const [title, setTitle] = useState(navPoints[0].node.name)

  const context = useContext(ThemeContext)
  const navContext = useContext(NavContext)
  const updateContext = ({ foreground, background, accent, navId }) => {
    context.setColors({
      foreground: foreground,
      background: background,
      accent: accent,
    })
    // set navigation
    navContext.setNav({ navId: navId })
    // change url
    window.history.pushState(null, null, `#${navId}`)
    setTitle(
      navPoints.find(p => {
        return p.node.slug === navId
      }).node.name
    )
  }

  return (
    <ThemeContext.Consumer>
      {theme => (
        <NavContext.Consumer>
          {nav => (
            <Layout>
              <SEO title={title} />
              <InView
                as="div"
                onChange={(inView, entry) =>
                  inView
                    ? updateContext({
                        foreground: "#333",
                        background: "#FFF",
                        accent: "#FFE747",
                        navId: "start",
                      })
                    : ""
                }
                rootMargin={rootMargin}
                trashold={TRESHOLD}
                id="start"
              >
                <Section foreground="#333" background="#FFF" accent="#FFE747">
                  <div className="layout-container">
                    <p className="paragraph paragraph--small margin-top-super">
                      Ich bin
                    </p>
                    <p className="paragraph paragraph--super">Joshua Stübner</p>

                    <p className="paragraph paragraph--small margin-top-xl">
                      ein
                    </p>
                    <h1 className="superHeadline">
                      Web Developer und Freund von Essen
                    </h1>
                    <p className="paragraph paragraph--small margin-top-xl">
                      und hier kannst du mich erreichen
                    </p>
                    <a
                      className="link"
                      href="mailto:kontakt@joshuastuebner.com"
                    >
                      kontakt
                      <span className="link-breakline">
                        @joshuastuebner.com
                      </span>
                    </a>
                    <p className="paragraph margin-top-auto centered more">
                      Noch nicht genug?
                    </p>
                  </div>
                </Section>
              </InView>
              <InView
                as="div"
                onChange={(inView, entry) =>
                  inView
                    ? updateContext({
                        foreground: "#222",
                        background: "#FFF8CC",
                        accent: "#FFDD00",
                        navId: "projekte",
                      })
                    : ""
                }
                rootMargin={rootMargin}
                id="projekte"
              >
                <Section
                  foreground="#222"
                  background="#FFF8CC"
                  accent="#FFDD00"
                >
                  <div className="layout-container">
                    <SectionHeadline>Projekte</SectionHeadline>
                    <ProjectsGrid>
                      {projects.map((project, index) => (
                        <Project
                          name={project.node.name}
                          link={project.node.link}
                          subheadline={project.node.subheadline}
                          description={project.node.description}
                          image={project.node.image}
                          key={project.node.name}
                          index={index}
                        />
                      ))}
                    </ProjectsGrid>
                    <FurtherLinkContainer>
                      <a
                        href="https://gitlab.com/JSHSJ"
                        className="link--further"
                        title="Mein Gitlab Profil"
                        aria-label="Gitlab Profil"
                      >
                        <Gitlab className="icon" />
                      </a>
                      <a
                        href="https://github.com/JSHSJ"
                        className="link--further"
                        title="Mein Github Profil"
                        aria-label="Github Profil"
                      >
                        <Github className="icon" />
                      </a>
                    </FurtherLinkContainer>
                  </div>
                </Section>
              </InView>
              <InView
                as="div"
                onChange={inView =>
                  inView
                    ? updateContext({
                        foreground: "#141414",
                        background: "#FFE747",
                        accent: "#FFF",
                        navId: "persoenliches",
                      })
                    : ""
                }
                rootMargin={rootMargin}
                id="persoenliches"
              >
                <Section
                  foreground="#141414"
                  background="#FFE747"
                  accent="#FFF"
                >
                  <div className="layout-container">
                    <SectionHeadline>Persönliches</SectionHeadline>
                    <p className="paragraph paragraph--big">
                      Hallo! Ich bin Joshua. Ich baue Webseiten und
                      -Applikationen, aber beschäftige mich auch anderen
                      Facetten des Programmierens und des Lebens. Zum Beispiel mit Fotografie, Kochen und Rezepte schreiben - und das mit
                      Freude.
                    </p>
                    <TechContainer className="margin-top-xxl">
                      <h3 className="headline--small">Was ich gern benutze:</h3>
                      <ul className="list">
                        <li className="list-Item">
                          Javascript :: Vue - Nuxt.js // React - Gatsby // ES6 // Web Components
                          // ...
                        </li>
                        <li className="list-Item">HTML, CSS :: SCSS</li>
                        <li className="list-Item">Go</li>
                        <li className="list-Item">
                          Linux / Unix-basierte Systeme
                        </li>
                        <li className="list-Item">
                          Messer und Pfannen aus Karbonstahl
                        </li>
                        <li className="list-Item">
                          Meine Kamera
                        </li>
                      </ul>
                    </TechContainer>
                    <TechContainer>
                      <h3 className="headline--small">
                        Worauf ich dabei wert lege:
                      </h3>
                      <ul className="list">
                        <li className="list-Item">Performance</li>
                        <li className="list-Item">Klarheit, Gutes UI</li>
                        <li className="list-Item">
                          Freude in Anwendung und Kreation
                        </li>
                        <li className="list-Item">
                          Neugierde und feine Aromen
                        </li>
                      </ul>
                    </TechContainer>
                    <h3 className="headline--small">
                      Und was ich sonst gerne mache:
                    </h3>
                    <InterestContainer>
                      {interests.map(i => (
                        <Interest key={i.node.name}>
                          {interestIcons[i.node.icon]}
                          <p className="paragraph paragraph--big">
                            {i.node.name}
                          </p>
                        </Interest>
                      ))}
                    </InterestContainer>
                    <FurtherLinkContainer>
                      <a
                        href="https://www.instagram.com/joshstuebner/"
                        className="link--further"
                        title="Mein Instagram Profil"
                        aria-label="Instagram Profil"
                      >
                        <Instagram className="icon" />
                      </a>
                      <a
                        href="https://twitter.com/JoshuaStubner"
                        className="link--further"
                        title="Mein Twitter Profil"
                        aria-label="Twitter Profil"
                      >
                        <Twitter className="icon" />
                      </a>
                    </FurtherLinkContainer>
                  </div>
                </Section>
              </InView>
              <InView
                as="div"
                onChange={(inView, entry) =>
                  inView
                    ? updateContext({
                        foreground: "#FFF8CC",
                        background: "#222",
                        accent: "#FFDD00",
                        navId: "kontakt",
                      })
                    : ""
                }
                rootMargin={rootMargin}
                id="kontakt"
              >
                <Section
                  foreground="#FFF8CC"
                  background="#222"
                  accent="#FFDD00"
                >
                  <div className="layout-container">
                    <SectionHeadline>Kontakt</SectionHeadline>
                    <p className="paragraph paragraph--big margin-top-xxl">
                      Falls du etwas brauchst, schick mir ‘ne Mail und wir
                      gucken, was wir zusammen machen können.
                    </p>
                    <a
                      className="link link--contact margin-top-m"
                      href="mailto:kontakt@joshuastuebner.com"
                    >
                      kontakt
                      <span className="link-breakline">
                        @joshuastuebner.com
                      </span>
                    </a>
                    <FurtherLinkContainer>
                      <a
                        href="https://www.linkedin.com/in/joshua-st%C3%BCbner-b9402a177/"
                        className="link--further"
                        title="Mein LinkedIn Profil"
                        aria-label="LinkedIn Profil"
                      >
                        <LinkedIn className="icon" />
                      </a>
                    </FurtherLinkContainer>
                  </div>
                </Section>
              </InView>
            </Layout>
          )}
        </NavContext.Consumer>
      )}
    </ThemeContext.Consumer>
  )
}

export default IndexPage
