import React from "react"
import styled from "styled-components"
import ThemeContext from "../context/ThemeContext"

const Wrapper = styled.section`
  --accent: ${props => (props.accent ? props.accent : "#FFE747")};
  --foreground: ${props => (props.foreground ? props.foreground : "black")};
  --background: ${props => (props.background ? props.background : "white")};

  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 80px 0;
  color: ${props => (props.foreground ? props.foreground : "black")};
  background-color: ${props => (props.background ? props.background : "white")};
  position: relative;
  transition: background-color 0.5s;
  align-items: flex-start;
  width: 100%;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    background-color: ${props =>
      props.foreground ? props.foreground : "black"};
    will-transform: rotate;

    transition: transform 0.5s;
  }

  &.active::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  @media (min-width: 680px) {
    padding: 120px 0;
  }
`

const Section = ({ children, foreground, background, accent }) => {
  return (
    <ThemeContext.Consumer>
      {theme => {
        return (
          <Wrapper
            className={
              theme.foreground === foreground ? "section active" : "section"
            }
            foreground={theme.foreground}
            background={theme.background}
            accent={theme.accent}
          >
            {children}
          </Wrapper>
        )
      }}
    </ThemeContext.Consumer>
  )
}

export default Section
