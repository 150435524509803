import React, { useContext } from "react"
import Img from "gatsby-image"
import styled from "styled-components"

import ImageContext from "../context/ImageContext"

const Container = styled.article`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 480px;

  @media (min-width: 680px) {
    flex-direction: row;
    max-width: 100%;
    justify-content: flex-start;
  }

  @media (min-width: 1024px) {
    min-height: 640px;
    justify-content: inherit;
    flex-direction: column;
  }
`

const Project = ({ name, link, subheadline, description, image, index }) => {
  const imageContext = useContext(ImageContext)

  const openImage = () => {
    imageContext.setImage({ image: image.childImageSharp, imageName: name })
  }

  return (
    <Container>
      <div className="project-text">
        <a
          href={link}
          className="link link--headline"
          dangerouslySetInnerHTML={{ __html: name }}
        ></a>
        <p className="paragraph paragraph--big">{subheadline}</p>
        <p className="paragraph">{description}</p>
      </div>
      <div
        onClick={openImage}
        onKeyDown={openImage}
        className="image project-image"
        role="button"
        tabIndex={index}
      >
        <Img
          fluid={image.childImageSharp.fluid}
          className="image project-image"
        />
      </div>
    </Container>
  )
}

export default Project
